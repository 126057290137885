import React from 'react';
import {Link} from "react-router-dom";
import AppIcon from "../shared/appIcon";
import AppStoreButton from "../shared/appStoreButton";

import "./scss/header.scss"

function Header() {

    return (
        <div className="header-container">
            <div className="icon-container">
                <Link to="/">
                    <AppIcon size={"large"} />
                </Link>
                <div className="description">
                    <h2>Whiteboard: just draw together</h2>
                    <p>The easiest to use drawing app for kids. Simple, easy, focused and fun.</p>
                </div>
            </div>

            <div className="links">
                <AppStoreButton />
            </div>
        </div>
    );
}

export default Header;
