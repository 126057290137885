import React, {useEffect, useState} from 'react';

import Markdown from "markdown-to-jsx";
import { Remarkable } from 'remarkable';

import "./scss/markDown.scss";


function RenderMarkdown(props) {
    const [markdown, setMarkdown] = useState('');

    let md = new Remarkable();
    md.set({
        html: true,
        breaks: true
    });

    useEffect(() => {
        console.log(props.page);
        fetch(props.page)
            .then((response) => {
                return response.text();
            })
            .then((text) => {
                setMarkdown(text);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])

    return (
        <div className="markdown-container">
            <Markdown>{md.render(markdown)}</Markdown>
        </div>
    );
}

export default RenderMarkdown;
