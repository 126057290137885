import React from "react";

import RenderMarkdown from "./renderMarkdown";

import {PRIVACY} from "../../utilities/constants";

import "./scss/privacy.scss";

function Privacy() {

    return(
        <div className="privacy">
            <RenderMarkdown page={PRIVACY} />
        </div>
    );
}

export default Privacy;
