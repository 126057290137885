import React, {useEffect, useState} from 'react';
import {getAppInfo} from "../../api/appInfoAPI";
import './scss/appIcon.scss'

import icon from './img/512x512bb.jpg';

function AppIcon(props) {
    const [image, setImage] = useState({});

    useEffect(() => {
        getAppInfo()
            .then((response) => {
                setImage({
                    small: response.data.results[0].artworkUrl60,
                    medium: response.data.results[0].artworkUrl100,
                    large: response.data.results[0].artworkUrl512,
                });
            })
            .catch((error) => {
                console.log(error);
            })
    },[]);

    return (
        <div>
            <img className="app-icon-img" src={image[props.size] ? image[props.size] : icon} alt="icon" />
        </div>
    );
}

export default AppIcon;
