import React from 'react';

import './undoRedoClear.scss';

function UndoRedoClear(props) {
    function handleClear(event) {
        event.preventDefault();
        props.resetCanvas();
    }

    function handleUndo(event) {
        event.preventDefault();
        props.undo();
    }

    function handleRedo(event) {
        event.preventDefault();
        props.redo();
    }

    return (
        <div className="reset-undo-tool">
            <div className="reset-button" onClick={handleClear}>
                <i className="fas fa-trash"></i>
            </div>
            <div className="undo-button" onClick={handleUndo}>
                <i className="fas fa-undo-alt"></i>
            </div>
            <div className="redo-button" onClick={handleRedo}>
                <i className="fas fa-redo-alt"></i>
            </div>
        </div>
    );
}

export default UndoRedoClear;
