import React from "react";

import "./toolButton.scss";

function ToolButton(props) {

    let icon = '';
    if(props.data.name === "eraser") {
        icon = <i className="fas fa-eraser"></i>;
    } else if(props.data.name === "brush") {
        icon = <i className="fas fa-paint-brush"></i>;
    }

    return(
        <div className="tool-button">
            <div className={props.data.name}>
                { icon }
            </div>
        </div>
    );
}

export default ToolButton;
