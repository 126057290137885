import React from 'react';
import {Route, Switch} from 'react-router-dom';

import Header from "./components/main/header";
import Footer from "./components/main/footer";
import BoardApp from "./components/board/boardApp";
import ChangeLog from "./components/info/changeLog";
import Privacy from "./components/info/privacy";

import './tailwind.output.css';
import './App.css';

//TODO setup error log handler

function App() {

    return (
        <div className="app main-container">
            <Header />

            <Switch>
                <Route path="/" exact>
                    <BoardApp />
                </Route>

                <Route path="/changelog" exact>
                    <ChangeLog />
                </Route>

                <Route path="/privacy" exact>
                    <Privacy />
                </Route>

                <Route path="/blog" exact>
                </Route>
            </Switch>

            <Footer />
        </div>
    );
}

export default App;
