import React, {useContext, useEffect, useRef, useState} from 'react';
import CanvasContext from "../../contexts/canvasContext";
import "./scss/canvas.scss";

function BoardCanvas() {
    const {tools, reset, history, setHistory, redo, setRedo} = useContext(CanvasContext);
    const [coordinates, setCoordinates] = useState([]);

    const paint = useRef(false);
    const workingTools = useRef({});

    const canvasWidth = window.innerWidth;
    const canvasHeight = (window.innerHeight * 0.8);

    useEffect(() => {
        const canvasContext = document.querySelector('#canvas').getContext('2d');

        canvasContext.strokeStyle = tools.color;
        canvasContext.lineJoin = tools.shape;
        canvasContext.lineWidth = tools.size;
        canvasContext.lineCap = 'round';
    }, []);

    useEffect(() => {
        setContextTool();

        workingTools.current = tools;
    }, [tools])

    useEffect(() => {
        if(reset === true) {
            paint.current = false;

            clearCanvas();
            setHistory([]);
        }
    }, [reset])

    useEffect(() => {
        if(history.length > 0 || redo.length > 0) {
            redraw();
        }
    }, [redo])

    function clearCanvas() {
        const canvasContext = document.querySelector('#canvas').getContext('2d');
        canvasContext.clearRect(0, 0, canvasWidth, canvasHeight);
    }

    function setContextTool() {
        const canvasContext = document.querySelector('#canvas').getContext('2d');
        canvasContext.strokeStyle = tools.eraser ? "#fff" : tools.color;
        canvasContext.lineJoin = tools.shape;
        canvasContext.lineWidth = tools.size;
    }

    function handleStart(event) {
        event.preventDefault();

        paint.current = true;
        setCoordinates([...coordinates, getCoords(event)]);
        draw();
    }

    function handleMove(event) {
        event.preventDefault();

        if(paint.current){
            setCoordinates([...coordinates, getCoords(event)]);
            draw();
        }
    }

    function handleEnd(event) {
        event.preventDefault();

        paint.current = false;

        if(coordinates.length > 0) {
            setHistory([...history, {
                coords: coordinates,
                tool: Object.assign({}, tools)
            }]);
        }

        draw();
        setRedo([]);
        setCoordinates([]);
    }

    function draw() {
        const canvasContext = document.querySelector('#canvas').getContext('2d');

        coordinates.forEach((coordinate, index) => {
            canvasContext.beginPath();
            canvasContext.moveTo(coordinate.x, coordinate.y)
            if(index + 1 < coordinates.length) {
                canvasContext.lineTo(coordinates[index + 1].x, coordinates[index + 1].y);
            }
            canvasContext.closePath();
            canvasContext.stroke();
        });
    }

    function redraw() {
        const canvasContext = document.querySelector('#canvas').getContext('2d')

        clearCanvas();
        history.forEach((pos) => {
            pos.coords.forEach((coordinate, index) => {
                canvasContext.strokeStyle = pos.tool.eraser ? "#fff" : pos.tool.color;
                canvasContext.lineJoin = pos.tool.shape;
                canvasContext.lineWidth = pos.tool.size;
                canvasContext.beginPath();
                canvasContext.moveTo(coordinate.x, coordinate.y)
                if(index + 1 < pos.coords.length) {
                    canvasContext.lineTo(pos.coords[index + 1].x, pos.coords[index + 1].y);
                }
                canvasContext.closePath();
                canvasContext.stroke();
            });
        });
        setContextTool();
    }

    function getCoords(event) {
        const canvas = document.querySelector('#canvas');
        const offset = canvas.getBoundingClientRect();

        if(event.type === 'touchstart' || event.type === 'touchmove') {
            let x = event.touches[0].clientX;
            let y = event.touches[0].clientY - offset.y - window.scrollY;
            return {x: x, y: y};
        } else if(event.type === 'mousedown' || event.type === 'mousemove') {
            let x = event.clientX;
            let y = event.clientY - offset.y - window.scrollY;
            return {x: x, y: y};
        }
    }

    return (
        <div id="canvas-container">
            <canvas
                id="canvas"
                className={`${!tools.eraser ? 'brush-cursor ' : 'eraser-cursor '}canvas`}
                width={canvasWidth}
                height={canvasHeight}

                onMouseMove={handleMove}
                onMouseDown={handleStart}
                onMouseUp={handleEnd}
                onMouseLeave={handleEnd}

                onTouchStart={handleStart}
                onTouchMove={handleMove}
                onTouchEnd={handleEnd}
            >
                Your browser does not support canvas element.
            </canvas>
        </div>
    );
}

export default BoardCanvas;
