export const initialColors = [
    {id: 0, name: 'multi', value: '#fff', selected: false},
    {id: 1, name: 'red', value: '#FF3B30', selected: true},
    {id: 2, name: 'orange', value: '#FF9500', selected: false},
    {id: 3, name: 'yellow', value: '#FFCC00', selected: false},
    {id: 4, name: 'green', value: '#34C759', selected: false},
    {id: 5, name: 'blue', value: '#007AFF', selected: false},
    {id: 6, name: 'purple', value: '#AF52DE', selected: false},
    {id: 13, name: 'black', value: '#000', selected: false},
    {id: 11, name: 'gray', value: '#8E8E93', selected: false},
    {id: 12, name: 'white', value: '#FFF', selected: false},
    {id: 10, name: 'tan', value: '#F9DCBE', selected: false},
    {id: 9, name: 'brown', value: '#9A6440', selected: false},
    {id: 8, name: 'teal', value: '#31B8BF', selected: false},
    {id: 7, name: 'pink', value: '#FC57CC', selected: false},
];

export const lineWidths = [
    {id: 1, name: 'two', value: 2, selected: false},
    {id: 2, name: 'four', value: 4, selected: true},
    {id: 3, name: 'eight', value: 8, selected: false},
    {id: 4, name: 'fourteen', value: 14, selected: false},
    {id: 5, name: 'twenty', value: 20, selected: false},
    {id: 6, name: 'thirty', value: 30, selected: false}
];

export const brushes = [
    {id: 1, name: 'brush', value: 'brush', selected: true},
    {id: 2, name: 'eraser', value: 'eraser', selected: false},
];

export const initialState = {
    color: "#FF3B30",
    size: 8,
    shape: 'round',
    eraser: false,
};
