import React, {useState} from "react";

import ToolButton from "./toolButton";

import "./toolDropdown.scss";

function ToolDropdown(props) {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(props.optionsList[parseInt(props.selected)]);

    function toggleOpen(event) {
        event.preventDefault();
        setOpen(!open);
    }

    function handleSelect(option) {
        setSelected(option);
        props.handleChange(option);
        setOpen(!open);
    }

    function findCorner(index) {
        if (props.optionsList.length <= 6) {
            if (index === 0) {
                return " start";
            } else if (index === props.optionsList.length - 1) {
                return " end";
            } else {
                return "";
            }
        } else {
            if (index === 0) {
                return " top-right";
            } else if (index === 6) {
                return " bottom-right";
            } else if (index === 7) {
                return " top-left";
            } else if (index === 13) {
                return " bottom-left";
            } else {
                return "";
            }
        }
    }

    return (
        <div className="tool-dropdown">
            <div className="selected-container" onClick={toggleOpen} onTouchEnd={toggleOpen}>
                <div className="selected-payload">
                    <ToolButton data={selected}/>
                </div>
            </div>
            <div className={open ? "options-container open" : "options-container"}>
                {props.optionsList.map((option, index) => {

                    return (
                        <div className={`option${findCorner(index)}`}
                             onClick={(event) => {
                                 event.preventDefault();
                                 handleSelect(option);
                             }}
                             onTouchEnd={(event) => {
                                 event.preventDefault();
                                 handleSelect(option);
                             }}
                             key={option.id}
                        >
                            <ToolButton key={option.id} data={option}/>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default ToolDropdown;
