import React, {useState} from 'react';
import BoardCanvas from "./boardCanvas";

import {brushes, initialColors, initialState, lineWidths} from "../shared/canvasToolsPresets";
import CanvasContext from "../../contexts/canvasContext";
import ToolDropdown from "./tools/toolDropdown/toolDropdown";

import "./scss/board.scss";
import UndoRedoClear from "./tools/editor/undoRedoClear";

function BoardApp() {
    const [tools, setTools] = useState(initialState);
    const [reset, setReset] = useState(false);
    const [history, setHistory] = useState([]);
    const [redo, setRedo] = useState([]);

    function updateColor(color) {
        setTools({...tools, color: color.value});
    }

    function updateSize(size) {
        setTools({...tools, size: size.value});
    }

    function updateBrush(brush) {
        if(brush.name === "eraser") {
            setTools({...tools, eraser: true})
        } else {
            setTools({...tools, eraser: false})
        }
    }

    function resetCanvas() {
        setReset(true);
        setTimeout(() => {
            setReset(false);
        }, 200);
    }

    function handleUndo() {
        if(history.length > 0) {
            setRedo([...redo, history[history.length - 1]]);
            setHistory(history.slice(0, history.length - 1));
        }
    }

    function handleRedo() {
        if(redo.length > 0) {
            setHistory([...history, redo[redo.length - 1]]);
            setRedo(redo.slice(0, redo.length - 1));
        }
    }

    return (
        <CanvasContext.Provider value={{tools, reset, history, setHistory, redo, setRedo}}>
            <div className="canvas-container">
                <div className="tools">
                    <div className="clear">
                        <UndoRedoClear resetCanvas={resetCanvas} undo={handleUndo} redo={handleRedo}/>
                    </div>
                    <div className="colors">
                        <ToolDropdown optionsList={initialColors} handleChange={updateColor} selected="1" />
                    </div>
                    <div className="lines">
                        <ToolDropdown optionsList={lineWidths} handleChange={updateSize} selected="2" />
                    </div>
                    <div className="brushes">
                        <ToolDropdown optionsList={brushes} handleChange={updateBrush} selected="0" />
                    </div>
                </div>
                <BoardCanvas />
            </div>
        </CanvasContext.Provider>
    );
}

export default BoardApp;
