import React from "react";

import RenderMarkdown from "./renderMarkdown";

import {CHANGE} from "../../utilities/constants";

import "./scss/changeLog.scss";

function ChangeLog() {

    return(
        <div className="change-log">
            <RenderMarkdown page={CHANGE} />
        </div>
    );
}

export default ChangeLog;
