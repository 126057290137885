import React from 'react';
import {Link} from "react-router-dom";

import './scss/footer.scss'

function Footer() {
    return (
        <div className="footer-container">
            <div className="copy">
                <p>Handcrafted by <a href="#">Jim Schultz</a> and <a href="#">Chris Schultz</a> in San Antonio, Texas</p>
            </div>

            <div className="footer-links">
                <a href="mailto:jim@getwhiteboard.com">Contact</a>
                <Link to="/changelog">What's New</Link>
                <Link to="/privacy">Privacy Policy</Link>
            </div>
        </div>
    );
}

export default Footer;
