import axios from 'axios';

const APPSTORE_URL = process.env.REACT_APP_APPSTORE_URL;

export function getAppInfo() {
    return axios.get(APPSTORE_URL)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        })
}
